import React, { useState } from "react";
import { Link } from "gatsby";
export default function HowItWorks() {
  const [slideNumber, setSlideNumber] = useState(1);
  const handleNextClick = e => {
    // Use event delegation to handle click
    if (e.target.getAttribute("id") === "next") {
      setSlideNumber(slideNumber >= 4 ? 1 : slideNumber + 1);
    }
  };
  const getCurrentSlide = () => {
    if (slideNumber === 1) {
      return (
        <div className="step">
          <div className="step-info">
            <h4 className="step-title">étape 1 : je choisis un espace</h4>
            <p className="step-description">
              Sélectionnez un espace de coworking, en fonction de vos besoins,
              votre positionnement géographique.
            </p>
            <button id="next">Suivant</button>
          </div>
          <img src="/img/selection_espace.png" alt="" className="step-img" />
        </div>
      );
    } else if (slideNumber === 2) {
      return (
        <div className="step">
          <div className="step-info">
            <h4 className="step-title">
              ÉTAPE 2 : JE ME CONNECTE À MON COMPTE
            </h4>
            <p className="step-description">
              Une fois votre espace sélectionné, vous êtes redirigé(e) vers la
              plateformes sécurisée de notre partenaires TheOffice. Il ne reste
              plus qu’à vous enregistrer ou à vous connecter si vous avez déjà
              un compte.
            </p>
            <button id="next">Suivant</button>
          </div>
          <img src="/img/vie-prive-pro.png" alt="" className="step-img" />
        </div>
      );
    } else if (slideNumber === 3) {
      return (
        <div className="step">
          <div className="step-info">
            <h4 className="step-title">
              ÉTAPE 3 : JE PAYE MA RÉSERVATION EN TOUTE SÉCURITÉ
            </h4>
            <p className="step-description">
              Une fois que vous avez sélectionné le moment (plage horaire ou
              journée(s)) où vous souhaitez venir travailler, il ne vous reste
              plus qu’à procéder au paiement sécurisé sur la plateforme de notre
              partenaire.
            </p>
            <button id="next">Suivant</button>
          </div>
          <img src="/img/connexion2.png" alt="" className="step-img" />
        </div>
      );
    } else if (slideNumber === 4) {
      return (
        <div className="step" style={{ flexDirection: "column" }}>
          <div className="step-info">
            <h4 className="step-title">
              ÉTAPE 4 : JE VAIS TRAVAILLER L'ESPRIT LIBRE !
            </h4>
          </div>
          <img
            style={{ alignSelf: "center" }}
            src="/img/devenir-cowo.png"
            alt=""
            className="step-img"
          />
          <button id="final">
            {" "}
            <Link to="/trouver-un-espace/#search">trouver un espace</Link>{" "}
          </button>
        </div>
      );
    }
  };
  return (
    <section id="howUseIt">
      <h1 className="howUseIt-title">réservez votre espace en 4 étapes</h1>
      <div className="container">
        <div className="steps-warper" onClick={handleNextClick}>
          {getCurrentSlide()}
        </div>
      </div>
    </section>
  );
}
