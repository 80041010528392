import React from "react";
import Hero from "../components/Hero";
import Footer from "../components/layout/Footer";
import ContactForm from "../components/contact/ContactForm";
import HowItWorks from "../components/HowItWorks";
const CommentCaFonctionne = () => {
  const heroContent = {
    title: "COWORKEZ EN TOUTE SIMPLICITÉ",
    paragraph:
      "Pour vous simplifier la vie et que vous soyez pleinement concentré(e) sur votre travail, on a pensé à un service de coworking de proximité, simple à utiliser.",
    bgImage:
      "https://images.unsplash.com/photo-1472289065668-ce650ac443d2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80",
    cta: {
      ctaTitle: "JE DÉCOUVRE LE FONCTIONNEMENT",
      ctaLink: "",
    },
  };

  return (
    <React.Fragment>
      <Hero data={heroContent} />
      <HowItWorks />
      <section id="services">
        <div className="container">
          <h2 className="services-title">
            DES SERVICES POUR VOUS FACILITER LA VIE
          </h2>
          <div className="services-warper">
            <div className="service">
              <img
                src="img/icon/Pense-atout-wifi.png"
                alt=""
                className="service-image"
              />
              <h4 className="service-title">INTERNET ULTRARAPIDE</h4>
              <p className="service-description">
                Tous les espaces de travail sont équipés avec une connexion Wifi
                haut débit, pour vous permettre de travailler vite et bien.
              </p>
            </div>
            <div className="service">
              <img
                src="img/icon/Pense-atout-imp.png"
                alt=""
                className="service-image"
              />
              <h4 className="service-title">IMPRIMANTE</h4>
              <p className="service-description">
                Besoin d’imprimer un document ? Vous retrouverez dans chaque
                espace un système d’impression.
              </p>
            </div>
            <div className="service">
              <img
                src="img/icon/Pense-atout-cafe.png"
                alt=""
                className="service-image"
              />
              <h4 className="service-title">ESPACE DÉTENTE </h4>
              <p className="service-description">
                Pour la pause café et vous détendre quand vous le souhaitez,
                certains espaces TheOffice disposent d’un espace détente.
              </p>
            </div>
            <div className="service">
              <img
                src="img/icon/Pense-atout-briefcase.png"
                alt=""
                className="service-image"
              />
              <h4 className="service-title">SÉCURITÉ ET SÉRÉNITÉ </h4>
              <p className="service-description">
                Casiers fermés et bureau verrouillé à distance, on a pensé à
                tout pour que vous soyez pleinement concentré sur votre travail.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <Footer />
    </React.Fragment>
  );
};
export default CommentCaFonctionne;
